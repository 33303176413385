<template>
  <v-navigation-drawer
    v-if="!$vuetify.breakpoint.mdAndUp"
    v-model="drawer"
    fixed
    temporary
    app
  >
      <v-list-item>
        <v-list-item-avatar>
          <v-img :src="profile.thumbnailImageURL"></v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <template
          v-for="item in items"
        >
          <v-list-group
            v-if="item.hasOwnProperty('children') && item.children.length"
            :key="item.title"
            :value="true"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="subItem in item.children"
              :key="subItem.title"
              v-bind="subItem.attrs"
              link
              class="pl-10"
            >
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            link
            v-bind="item.attrs"
            :key="item.title"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NavigationItems from '@/lib/navigationItems.json';

export default {
  name: 'MobileNavigationDrawer',
  data() {
    return {
      items: NavigationItems,
    };
  },
  computed: {
    ...mapGetters({ mobileDrawerIsActive: 'mobileDrawerIsActive', profile: 'user/profile' }),
    drawer: {
      get() {
        return this.mobileDrawerIsActive;
      },
      set(val) {
        if (!val) this.toggleMobileDrawer();
      },
    },
  },
  methods: {
    ...mapActions(['toggleMobileDrawer']),
  },
};
</script>
